<script setup lang="ts">
import {ref} from 'vue';

const list = ref<Array<{value: number, key: string, suffix?: string, position?: {x: number, y: number}}>>([]);

function addNumber(value: number, {suffix, position}: {suffix?: string, position?: {x: number, y: number}} = {}) {
    const key = `${value}-${Date.now()}-${suffix}-${position?.x}-${position?.y}`;
    list.value.push({value, suffix, position, key});
}

function finish(el: Element) {
    const key = el.getAttribute('data-key');
    list.value = list.value.filter((item) => item.key !== key);
}

defineExpose({
    addNumber,
});

</script>

<template>
    <TransitionGroup type="animation" name="appear" @after-enter="finish($event)">
        <div
            v-for="item in list"
            :key="item.key"
            :data-key="item.key"
            class="appeared-number"
            :style="item.position ? `left: ${item.position.x}px; top: ${item.position.y}px; margin-left: -12px; margin-top: -36px; width: auto;` : ''"
        >
            +{{ item.value }} {{ item.suffix }}
        </div>
    </TransitionGroup>
</template>

<style scoped>
.appeared-number {position: absolute; font-size: 24px; font-weight: 600; width: 100%; left: 0; top: 47%; opacity: 0; text-shadow: 0 0 40px #000; z-index: 1; pointer-events: none;}

.appear-enter-active {animation: appear 1.2s;}

@keyframes appear {
    0% {opacity: 0; transform: translateY(0);}
    20% {
        opacity: 1;
    }
    100% {transform: translateY(-110px); opacity: 0}
}
</style>
