export function preloadMedia(mediaElement: HTMLMediaElement) {
    if (isLoaded(mediaElement)) {
        return Promise.resolve();
    }
    const {promise, resolve, reject} = Promise.withResolvers();

    const isVideo = mediaElement.tagName === 'VIDEO';
    if (isVideo) {
        mediaElement.style.width = '1px';
        mediaElement.style.height = '1px';

        window.addEventListener('click', startLoading, {once: true});

        mediaElement.addEventListener('canplaythrough', handleFinish);
        mediaElement.addEventListener('ended', handleFinish);

        function startLoading() {
            mediaElement.play();
        }

        function handleFinish() {
            if (isLoaded(mediaElement)) {
                mediaElement.pause();
                mediaElement.currentTime = 0;
                mediaElement.currentTime = 0;
                mediaElement.style.width = '';
                mediaElement.style.height = '';
                mediaElement.removeEventListener('canplaythrough', handleFinish);
                mediaElement.removeEventListener('ended', handleFinish);
                window.removeEventListener('click', startLoading);
                resolve(undefined);
            }
        }
    }

    return promise;
}

function isLoaded(mediaElement: HTMLMediaElement) {
    return mediaElement.readyState === window.HTMLMediaElement.HAVE_ENOUGH_DATA || mediaElement.ended;
}