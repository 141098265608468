<script>
export default {

};
</script>

<template>
    <div class="u-flex u-flex--justify-center u-flex--align-center">
        <a class="social-link link--opacity" href="https://t.me/GemHQ" target="_blank" v-haptic-feedback>
            <img src="/img/icon-social-tg.svg" alt="Telegram" width="24" height="24">
        </a>
        <a class="social-link link--opacity" href="https://www.youtube.com/@GemOfTon/featured" target="_blank" v-haptic-feedback>
            <img class="u-icon" src="/img/icon-social-yt.svg" alt="YouTube" width="24" height="24">
        </a>
        <a class="social-link link--opacity" href="https://twitter.com/GEMofTON" target="_blank" v-haptic-feedback>
            <img class="u-icon" src="/img/icon-social-x.svg" alt="Twitter" width="24" height="24">
        </a>
        <a class="social-link link--opacity" href="https://app.ston.fi/pools/EQDSvEhM2LBMP2_ewLkVgFowolZ2J-gKfet8Q5PLHq0GHj9X" target="_blank" v-haptic-feedback>
            <img class="u-icon" src="/img/icon-social-ston.svg" alt="STON.fi" width="24" height="24">
        </a>
    </div>
</template>

<style scoped>
.social-link {
    display: inline-block; line-height: 0;
    &+& {margin-left: 16px;}
}
</style>
