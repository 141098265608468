// https://github.com/scopsy/await-to-js
export function tryFlat<T, U = Error>(
    promise: Promise<T>,
    errorExt?: object,
): Promise<[U, undefined] | [undefined, T]> {
    return promise
        .then<[undefined, T]>((data: T) => [undefined, data])
        .catch<[U, undefined]>((err: U) => {
            if (errorExt) {
                const parsedError = Object.assign({}, err, errorExt);
                return [parsedError, undefined];
            }

            return [err, undefined];
        });
}
