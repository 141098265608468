<script lang="ts">
import {defineNuxtComponent} from "#app";
import {animate} from 'popmotion';
import {EventManager, type MjolnirGestureEvent, type MjolnirPointerEvent} from 'mjolnir.js';
import type {MjolnirEvent} from 'mjolnir.js';
import {wait} from "@shrpne/utils/src/wait.js";
import {isAndroid} from "~/utils/user-agent.ts";
import {tryFlat} from "~/utils/async.ts";
import type {ExpGesture} from "~/types.d.ts";
import {rotateCamera} from '~/lib/gem-three/rotate.js';
import {GPUBenchmark} from "~/lib/benchmark.ts";

let eventManager: EventManager;

export default defineNuxtComponent({
    emits: {
        gesture: (gesture: ExpGesture) => true,
    },
    data() {
        return {
            isRenderError: false,
        };
    },
    async mounted() {
        (document.getElementById('webgl') as HTMLElement).addEventListener('touchmove', (e) => {
            e.preventDefault();
        }, {passive: false});

        let shouldLoadSimple = false;
        if (isAndroid) {
            const [error, performance] = await tryFlat(new GPUBenchmark().run(true));
            if (error || performance === 'low') {
                shouldLoadSimple = true;
            }
        }

        const modulePromise = shouldLoadSimple
            ? import('~/lib/gem-three/gem-simple/index.ts')
            : import('~/lib/gem-three/main/index.ts');
        const {renderGem} = await modulePromise;
        const [renderError, renderResult] = await tryFlat(renderGem());
        if (renderError) {
            this.isRenderError = true;
        }

        eventManager = new EventManager(document.getElementById('webgl') as HTMLElement, {
            recognizerOptions: {
                swipe: {
                    threshold: 10,
                    velocity: 0.2,
                },
                tap: {
                    threshold: 5,
                    time: 250,
                },
                press: {
                    threshold: 9,
                    time: 251,
                },
            },
        });

        const handleSwipeLeft = (event: MjolnirGestureEvent) => {
            this.emitGesture('swipe', 'left', event);
            swipeHaptic();
        };
        const handleSwipeRight = (event: MjolnirGestureEvent) => {
            this.emitGesture('swipe', 'right', event);
            swipeHaptic();
        };
        const handleTap = (event: MjolnirGestureEvent) => {
            const isLeftPart = getClickSide(event);

            if (renderResult) {
                animateCamera(renderResult.camera, renderResult.controls, isLeftPart);
            }
            this.emitGesture('tap', isLeftPart ? 'left' : 'right', event);
            window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft');
        };

        eventManager.on({
            'swipeleft': handleSwipeLeft,
            'swiperight': handleSwipeRight,
            'tap': handleTap,
        });
    },
    unmounted() {
        // may be undefined because of async 'mounted'
        eventManager?.destroy();
    },
    methods: {
        emitGesture(type: ExpGesture['type'], direction: ExpGesture['direction'], event: MjolnirGestureEvent) {
            this.$emit('gesture', {
                type,
                direction,
                timestamp: new Date(),
                event,
            });
        },
    },
});

async function swipeHaptic() {
    const DELAY = 80;
    window.Telegram?.WebApp.HapticFeedback.notificationOccurred('success');
    await wait(DELAY);
    window.Telegram?.WebApp.HapticFeedback.notificationOccurred('error');
    await wait(DELAY);
    window.Telegram?.WebApp.HapticFeedback.notificationOccurred('error');
    await wait(DELAY);
    window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft');
    await wait(DELAY);
    window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft');
}

function getClickSide(event: MjolnirGestureEvent) {
    const e = event?.srcEvent;
    if (!e.target) {
        return;
    }
    const rect = (function offset(el: Element) {
        const box = el.getBoundingClientRect();
        const docElem = document.documentElement;
        return {
            top: box.top + window.pageYOffset - docElem.clientTop,
            left: box.left + window.pageXOffset - docElem.clientLeft,
            width: box.width,
            height: box.height,
        };
    })(e.target as Element);

    // offset from click to element's edge
    // e.clientX - rect.left
    const offset = event.offsetCenter.x;
    const isLeftPart = offset < (rect.width / 2);
    return isLeftPart;
}

const TAP_ROTATION = 1;

function animateCamera(camera, controls, backwards) {
    const DEG = 360 * TAP_ROTATION;
    const START = 0;
    const FINISH = 100;
    const direction = backwards ? -1 : 1;

    let prev = 0;
    animate({
        from: START,
        to: FINISH,
        type: 'spring',
        bounce: 0,
        duration: 600,
        onUpdate(progress) {
            const delta = progress - prev;
            prev = progress;
            const degDelta = delta * DEG / FINISH;
            rotateCamera(camera, degDelta * direction);
            controls.update();
        },
    });
}
</script>

<template>
    <div class="u-relative">
        <canvas id="webgl" :class="{'is-error': isRenderError}"/>
    </div>
</template>

<style scoped>
#webgl {touch-action: none; width: 100%; height: 240px; margin: -20px 0 -30px;}
#webgl.is-error {background: url(/img/logo-gem.svg) no-repeat center center; background-size: 128px 128px;}
</style>
