<script setup lang="ts">
import {ref, computed, watch} from 'vue';
import {animate} from 'popmotion';

const props = defineProps<{
    value: number|'',
    format?: (value: string|number) => string|number
}>();

const shouldDisplay = computed(() => {
    return typeof props.value == "number";
});

const displayed = ref<string|number>(props.value);
let stopAnimation: ReturnType<typeof animate>['stop'];

watch(() => props.value, () => {
    if (typeof stopAnimation === 'function') {
        stopAnimation();
    }

    if (!shouldDisplay.value) {
        // props.value guarded as number
        return;
    }
    const {stop} = animate({
        from: displayed.value,
        to: props.value as number,
        onUpdate(current) {
            displayed.value = current;
        },
    });

    stopAnimation = stop;
});
</script>

<template>
    <span class="u-text-number" v-if="shouldDisplay">
        {{ typeof props.format === 'function' ? props.format(displayed) : displayed }}
    </span>
</template>
