import {reactive, shallowRef, computed, toRef, readonly, onMounted, onUnmounted} from 'vue';
import type {ExpGesture, SequenceGestureSlug} from "~/types.d.ts";
import {loadDynamicSequence, sendSequence} from "~/api/tg-bot.js";

type SequenceDefinition = {
    name: string,
    list: Array<SequenceGestureSlug>,
    amount: number,
}

const SEQUENCE_THRESHOLD = 1000;
const SEQUENCES: Array<SequenceDefinition> = [
    {
        name: 's03',
        list: ['any', 'any', 'any'],
        amount: 1,
    },
    {
        name: 's05',
        list: ['any', 'any', 'any', 'any', 'any'],
        amount: 5,
    },
    {
        name: 's06',
        list: ['left', 'right', 'left', 'right', 'right', 'left'],
        amount: 7,
    },
    {
        name: 's07',
        list: ['left', 'left', 'left', 'right', 'left', 'left', 'right'],
        amount: 10,
    },
    {
        name: 's08',
        list: ['left', 'left', 'right', 'left', 'right', 'left', 'right', 'left'],
        amount: 15,
    },
    {
        name: 's09',
        list: ['left', 'right', 'right', 'right', 'left', 'right', 'right', 'left', 'left'],
        amount: 20,
    },
];

export const SEQUENCE_COMPLIMENT: Record<string, Array<string>> = {
    s07: [
        'Great job!',
        'Well done!',
        'Bravo!',
        'Excellent!',
        'Nice going!',
        'Spot on!',
        'Superb!',
        'Great effort!',
        'Impressive!',
        'Keep it up!',
    ],
    s08: [
        'Excellent!',
        'Brilliant!',
        'Wonderful effort!',
        'Splendid performance!',
        'Amazing job!',
        'Incredible!',
        'Keep it up!',
    ],
    s09: [
        'Keep it up!',
        'Well executed!',
        'Amazing job!',
        'Great execution!',
        'Fantastic effort!',
        'Marvelous!',
        'Top-notch job!',
        'Hats off to you!',
    ],
    s10: [
        'You nailed it!',
        'You did it!',
        'Splendid performance!',
        'Outstanding!',
        'Keep it up!',
    ],
};

export default function useExpSequence() {
    const state = reactive({
        sessionExp: {
            approved: 0,
            optimistic: 0,
        },
        emittedSequenceList: <Array<SequenceDefinition>> [],
        emittedGestureList: <Array<ExpGesture>> [],
    });

    const dynamicSequenceList = shallowRef<Array<SequenceDefinition>>([]);
    const gestureList = shallowRef<Array<ExpGesture>>([]);

    const sessionExpTotal = computed(() => {
        return state.sessionExp.approved + state.sessionExp.optimistic;
    });

    let timer: number;
    function handleGesture(gesture: ExpGesture) {
        const prevGesture = gestureList.value[gestureList.value.length - 1];
        if (prevGesture && gesture.timestamp.getTime() - prevGesture.timestamp.getTime() < 200) {
            return;
        }
        gestureList.value.push(gesture);
        state.emittedGestureList.push(gesture);
        state.sessionExp.optimistic += 1;
        clearTimeout(timer);
        if (gestureList.value.length >= 25) {
            checkSequence();
        } else {
            timer = window.setTimeout(checkSequence, SEQUENCE_THRESHOLD);
        }

    }

    function checkSequence() {
        // console.log(gestureList.value)
        const sequenceList = SEQUENCES.concat(dynamicSequenceList.value);
        const sequence = sequenceList.find((sequence) => {
            const slugList = sequence.list;
            if (slugList.length !== gestureList.value.length) {
                return;
            }
            return slugList.every((slug, index) => {
                const gesture = gestureList.value[index]!;
                return isGestureMatchSequence(gesture, slug);
            });
        });

        let comboExp = 0;
        const gestureExp = gestureList.value.length;
        if (sequence) {
            state.emittedSequenceList.push(sequence);
            comboExp = sequence.amount;
        }
        const totalSequenceOptimistExp = comboExp + gestureExp;
        state.sessionExp.optimistic += comboExp; // gestureExp already added during gestures
        sendSequence(gestureList.value)
            .then((result) => {
                state.sessionExp.optimistic -= totalSequenceOptimistExp;
                state.sessionExp.approved += result.exp;
            })
            .catch((error) => {
                console.log(error);
                state.sessionExp.optimistic -= totalSequenceOptimistExp;
            });

        gestureList.value = [];
    }

    function isGestureMatchSequence(gesture: ExpGesture, slug: SequenceGestureSlug) {
        if (slug === 'any') {
            return true;
        }
        const isExplicitlyDefined = slug.includes('-');
        if (isExplicitlyDefined) {
            const [type, direction] = slug.split('-');
            return direction === gesture.direction && type === gesture.type;
        } else {
            return slug === gesture.direction || slug === gesture.type;
        }
    }

    let updateDynamicSequenceTimer: number;
    async function updateDynamicSequence() {
        try {
            const result = await loadDynamicSequence();
            const slugList = result.sequence.split(',');
            dynamicSequenceList.value = [{
                name: 's10',
                list: slugList,
                amount: 50,
            }];

            const elapsedFromUpdate = Date.now() - new Date(result.lastUpdated).getTime();
            const timeout = Math.max(60 * 1000 - elapsedFromUpdate, 0);
            updateDynamicSequenceTimer = window.setTimeout(updateDynamicSequence, timeout);
        } catch (error) {
            updateDynamicSequenceTimer = window.setTimeout(updateDynamicSequence, 30 * 1000);
        }
    }

    function consumeEmittedSequences() {
        state.emittedSequenceList = [];
    }
    function consumeEmittedGestures() {
        state.emittedGestureList = [];
    }

    onMounted(() => {
        updateDynamicSequence();
    });

    onUnmounted(() => {
        clearTimeout(updateDynamicSequenceTimer);
    });

    return {
        sessionExp: sessionExpTotal,
        emittedSequenceList: readonly(toRef(state, 'emittedSequenceList')),
        emittedGestureList: readonly(toRef(state, 'emittedGestureList')),
        handleGesture,
        consumeEmittedSequences,
        consumeEmittedGestures
    };
}