import {watch, toRef} from 'vue';

/**
 * @param {string} url
 * @param {object} [options]
 * @param {boolean|import('vue').Ref<boolean>} [options.isPrefetchDuringUserInteraction]
 * @param {number} [options.volume]
 * @param {boolean} [options.loop]
 * @return {{play: (function(): Promise<void>), stop: stop, fetchDuringUserInteraction: fetchDuringUserInteraction}}
 */
export default function useAudio(url, {isPrefetchDuringUserInteraction, volume, loop} = {}) {
    /** @type {HTMLAudioElement} */
    let audioElement;

    // isPrefetchDuringUserInteraction may be ref or value
    watch(toRef(isPrefetchDuringUserInteraction), (newValue) => {
        if (newValue) {
            // fetchDuringUserInteraction has checks preventing multiple fetches, so can be called multiple times
            fetchDuringUserInteraction();
        }
    }, {immediate: true});

    async function fetch() {
        if (!checkUserInteraction()) {
            return;
        }
        audioElement = new Audio(url);
        if (volume) {
            audioElement.volume = volume;
        }
        if (loop) {
            audioElement.loop = loop;
        }
    }

    var isWaitingUserInteraction = false;
    function fetchDuringUserInteraction() {
        if (isWaitingUserInteraction || audioElement) {
            return;
        }
        isWaitingUserInteraction = true;
        window.addEventListener('click', fetch, {
            once: true,
            passive: true,
        });
    }

    async function play() {
        if (!audioElement) {
            await fetch();
        }
        return audioElement?.play()
            .catch((error) => {
                console.log('failed to play', audioElement.src, error);
            });
    }

    function stop() {
        if (!audioElement) {
            return;
        }
        audioElement.pause();
        audioElement.currentTime = 0;
    }

    function checkUserInteraction() {
        if (navigator.userActivation) {
            return navigator.userActivation.isActive;
        }
        return true;
    }

    return {
        play,
        stop,
        fetchDuringUserInteraction,
    };
}
