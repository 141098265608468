<script>
import {useAsyncData, defineNuxtComponent, showError} from '#imports';
import {wait, waitCondition} from '@shrpne/utils/src/wait.js';
import {useMainStore} from '~/store/index.js';
import {pretty, prettyRound} from '~/utils/pretty-num.js';
import {getRandomInteger} from '~/utils/random.js';
import {preloadMedia} from '~/utils/preload.ts';
import {acceptTerms as _acceptTerms, clamRefRewards, getReferrals} from '~/api/tg-bot.js';
import useExpSequence, {SEQUENCE_COMPLIMENT} from '~/composables/use-exp-sequence.ts';
import useAudio from '~/composables/use-audio.js';
import InlineSvg from 'vue-inline-svg';
import AnimationCounter from '~/components/base/AnimationCounter.vue';
import AnimationNumbers from '~/components/base/AnimationNumbers.vue';
import AnimationTypewriter from '~/components/base/AnimationTypewriter.vue';
import LayoutHeader from '~/components/LayoutHeader.vue';
import LayoutFooter from '~/components/LayoutFooter.vue';
import ShareModalWithButton from '~/components/ShareModalWithButton.vue';
import SocialLinks from '~/components/SocialLinks.vue';
import Gem from '~/components/Gem.vue';

export default defineNuxtComponent({
    components: {
        LayoutFooter,
        AnimationCounter,
        AnimationNumbers,
        AnimationTypewriter,
        InlineSvg,
        LayoutHeader,
        ShareModalWithButton,
        SocialLinks,
        Gem,
    },
    async setup() {
        const store = useMainStore();
        const {
            emittedSequenceList,
            emittedGestureList,
            sessionExp,
            handleGesture,
            consumeEmittedSequences,
            consumeEmittedGestures,
        } = useExpSequence();

        const {play: playAudio} = useAudio('/videos/congratulation-large.mp3', {isPrefetchDuringUserInteraction: true});

        // after registering lifecycle hooks
        await window.authorizationPromise
            .catch((error) => {
                showError({
                    statusMessage: 'Authorization failed: ' + error.message,
                });
            });

        const {status: claimStatus, data: isClaimed, execute: claimRewards} = useAsyncData(() => {
            if (!(store.user?.availableClaimBalance > 0)) {
                return Promise.resolve(false);
            }
            return clamRefRewards()
                .then(({success}) => success);
        }, {
            immediate: false,
        });


        const {status: termsStatus, execute: acceptTerms} = useAsyncData(async () => {
            await _acceptTerms();
            store.SET_TERMS(true);
        }, {
            immediate: false,
        });

        return {
            emittedSequenceList,
            emittedGestureList,
            sessionExp,
            expSequenceHandleGesture: handleGesture,
            consumeEmittedSequences,
            consumeEmittedGestures,

            playAudio,

            isClaimed,
            claimRewards,
            claimStatus,

            acceptTerms,
            termsStatus,
        };
    },
    data() {
        return {
            compliment: '',
            videoLoadPromise: undefined,
        };
    },
    computed: {
        gainedBalance() {
            const claimedBalance = this.isClaimed ? this.$store.user.availableClaimBalance : 0;
            return claimedBalance + this.sessionExp;
        },
        balance() {
            const currentBalance = this.$store.user ? Number(this.$store.user.balance) : 0;
            return currentBalance + this.gainedBalance;
        },
    },
    watch: {
        emittedSequenceList: {
            handler() {
                if (!this.emittedSequenceList.length) {
                    return;
                }
                this.emittedSequenceList.forEach((sequence) => {
                    // animate pop-out numbers
                    const suffix = sequence.name === 's10' ? 'Ultra Combo' : 'Combo';
                    this.$refs.animationNumbers.addNumber(sequence.amount, {suffix});
                    // animate text compliment
                    if (SEQUENCE_COMPLIMENT[sequence.name]) {
                        const index = getRandomInteger(0, SEQUENCE_COMPLIMENT[sequence.name].length - 1);
                        this.compliment = SEQUENCE_COMPLIMENT[sequence.name][index];
                    }
                    // animate firework video
                    if (sequence.name === 's10' || window.IS_DEBUG_MODE) {
                        this.playCongratulation();
                    }
                });
                this.consumeEmittedSequences();
                window.Telegram?.WebApp.HapticFeedback.impactOccurred('medium');
            },
            deep: true,
        },
        emittedGestureList: {
            handler() {
                if (!this.emittedGestureList.length) {
                    return;
                }
                this.emittedGestureList.forEach((gesture) => {
                    // animate pop-out numbers
                    this.$refs.animationNumbers.addNumber(1, {
                        position: gesture.event.offsetCenter,
                    });
                });
                this.consumeEmittedGestures();
            },
            deep: true,
        },
    },
    mounted() {
        this.$watch(() => this.$store.user?.termsAccepted, () => {
            if (this.$store.user.termsAccepted) {
                this.videoLoadPromise = waitCondition(() => {
                    return !!this.$refs['video-congratulation-large'];
                })
                    .then(() => {
                        return preloadMedia(this.$refs['video-congratulation-large']);
                    });
            }
        }, {immediate: true});
    },
    methods: {
        pretty,
        prettyRound,
        handleGesture(gesture) {
            this.expSequenceHandleGesture(gesture);

            if (this.$store.user.availableClaimBalance > 0 && this.claimStatus === 'idle' && !this.isClaimed) {
                this.claimRewards()
                    .then(async () => {
                        await Promise.race([
                            this.videoLoadPromise,
                            wait(1500),
                        ]);
                        this.$refs.animationNumbers.addNumber(this.$store.user.availableClaimBalance, {suffix: 'CREW REWARDS'});
                        window.Telegram?.WebApp.HapticFeedback.impactOccurred('medium');
                        this.playCongratulation();
                    });
            }
        },
        async playCongratulation() {
            /** @type {HTMLVideoElement} */
            const video = this.$refs['video-congratulation-large'];
            // if video ends, readyState changes to HAVE_CURRENT_DATA(2)
            if (video.readyState === window.HTMLMediaElement.HAVE_ENOUGH_DATA || video.ended) {
                // video.playbackRate = 1.4;
                video.currentTime = 0;
                video.play();
                this.playAudio();
            }
        },
    },
});

</script>

<template>
    <div class="page--index u-container u-section u-text-center">
        <div class="header u-flex u-flex--justify-between">
            <nuxt-link to="/wallet" v-haptic-feedback>
                <img class="u-image" src="/img/icon-gem.svg" alt="Home" width="24" height="24">
            </nuxt-link>

            <div class="header__avatar avatar avatar--24" :style="$store.user?.photo ? `background-image: url(${$store.user?.photo})` : ''"></div>
        </div>

        <h2 class="u-h2">
            <AnimationTypewriter v-if="compliment" :value="compliment"/>
            <template v-else>
                Hi, {{ $store.username }}
            </template>
        </h2>
        <img class="index__glow" src="/img/index-glow.svg" alt="" role="presentation">

        <!-- terms not accepted -->
        <template v-if="!$store.user?.termsAccepted">
            <div
                v-if="$store.user?.inviter.name"
                class="avatar avatar--128 u-image-center u-mt-25"
                :class="{'index__referrer-gem': !$store.user?.inviter.name}"
                :style="$store.user?.inviter.photo ? `background-image: url(${$store.user?.inviter.photo})` : ''"
            ></div>
            <template v-else>
                <video class="index__referrer-avatar--video u-image-center u-mt-25 u-hidden-ios" autoplay muted loop playsinline>
                    <source src="/videos/gem-logo.mp4" type="video/mp4; codecs=hvc1" />
                    <source src="/videos/gem-logo.webm" type="video/webm; codecs=vp9" />
                </video>
                <picture class="u-hidden-not-ios">
                    <source srcset="/videos/gem-logo.mp4" type="video/mp4; codecs=hvc1" />
                    <source srcset="/videos/gem-logo.webm" type="video/webm; codecs=vp9" />
                    <img class="index__referrer-avatar--video u-image-center u-mt-25 u-hidden-not-ios" src="/img/logo-gem.svg" alt="">
                </picture>
            </template>
            <h1 class="u-h1 u-mt-30 u-mb-25">
                <template v-if="$store.user?.inviter.name">
                    {{ $store.user?.inviter.name }} invites you&nbsp;to join Gem!
                </template>
                <template v-else>Welcome to Gem!</template>
            </h1>
            <div class="u-h u-h2 u-mb-25" style="max-width: 330px; margin-inline: auto;">
                Get your free
                <img src="/img/logo-gem.svg" width="16" height="16" alt="" role="presentation" style="margin-right: -1px; margin-left: 4px;">
                GEMs among the&nbsp;first in Telegram
            </div>
            <button type="button" class="button button--xlarge button--full button--main" @click="acceptTerms()" v-haptic-feedback="'medium'">Join</button>
            <div class="u-mb-20 u-mt-10 u-text-muted u-text-small" style="max-width: 240px; margin-inline: auto">
                By clicking this button you agree to&nbsp;<a class="link link--white link--hover u-display-ib" href="https://gem.bot/gembot-terms.pdf" target="_blank" v-haptic-feedback>Terms and Services</a> and <a class="link link--white link--hover u-display-ib" href="https://gem.bot/gembot-privacy-policy.pdf" target="_blank" v-haptic-feedback>Privacy Policy</a>
            </div>
        </template>

        <!-- terms accepted -->
        <template v-if="$store.user?.termsAccepted">
            <div class="index__gem u-relative">
                <Gem class="u-mt-15" @gesture="handleGesture($event)"/>
                <AnimationNumbers ref="animationNumbers"/>
                <video ref="video-congratulation-large" preload="auto" muted playsinline poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                    <source src="/videos/congratulation-large.mp4" type="video/mp4; codecs=hvc1" />
                    <source src="/videos/congratulation-large.webm" type="video/webm; codecs=vp9" />
                </video>
            </div>



            <h1 class="u-h1 u-mt-10 u-mb-025">
                <AnimationCounter :value="balance" :format="prettyRound"/>
                XP
            </h1>
            <div class="u-h--uppercase">
                +<AnimationCounter :value="gainedBalance" :format="prettyRound"/>
                XP for this visit
            </div>

            <ShareModalWithButton class="button button--main button--xlarge button--full u-mt-15"/>

            <div class="u-fw-600 u-mt-15">Your crew is</div>
            <div class="u-h u-h2 u-mt-025 u-mb-20">
                <a
                    href="javascript:Telegram.WebApp.openLink('https://telegra.ph/Invite-Rewards-07-23',{try_instant_view:true});"
                    class="link--dashed"
                    v-haptic-feedback="'rigid'"
                >
                    {{ $store.referralsCount || 0 }} FRIENDS
                </a>
            </div>
            <!--<div class="u-h&#45;&#45;uppercase u-mt-025 u-mb-35">including 0 inactive</div>-->
        </template>

        <!--<LayoutFooter/>-->
        <SocialLinks/>
    </div>
</template>

<style scoped>
.index__gem video {
    position: absolute;
    left: -50px;
    top: 50%;
    z-index: 1;
    width: calc(100% + 50px * 2);
    pointer-events: none;
    transform: translateY(-50%);
    margin-top: 110px;
}
</style>
