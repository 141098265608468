// https://stackoverflow.com/a/73365572/4936667
import 'core-js/es/array/to-sorted.js';
import {
    Mesh,
    MeshStandardMaterial,
    PerspectiveCamera,
    Scene,
    SphereGeometry,
    WebGLRenderer,
} from "three";

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export enum GPUPerformanceLevel {
    HIGH = "high",
    LOW = "low",
}

function approxRollingAverage(
    average: number,
    value: number,
    history = 50,
) {
    average -= average / history;
    average += value / history;

    return average;
}

/**
 * Three.js based webgl benchmark
 *
 * In summary, the `run` method adds `meshPerStep` new spheres every step (frame)
 * and measures the fps. If we're able to perform >=`thresholds.maxSteps` of these
 * steps, without the fps dropping below `thresholds.fps`, then we label the device
 * `GPUPerformanceLevel.HIGH`.
 */
export class GPUBenchmark {
    scene = new Scene();
    material = new MeshStandardMaterial();
    geometry = new SphereGeometry();

    static thresholds = { fps: 30, maxSteps: 6 };
    static meshPerFrame = 100;
    static framesPerStep = 1;

    async run(debug = false): Promise<GPUPerformanceLevel> {
        const camera = new PerspectiveCamera(75);
        const renderer = new WebGLRenderer();

        let tPrev = performance.now() / 1000;
        let currentStep = 0;
        let meshCnt = 0;
        // let fps = GPUBenchmark.thresholds.fps;
        const probes: number[] = [];

        let passedThreshold = false;

        const animate = async () => {
            currentStep += 1;

            const shouldAddMeshes = GPUBenchmark.framesPerStep === 1 || currentStep % GPUBenchmark.framesPerStep === 0;
            if (shouldAddMeshes) {
                meshCnt += this.step();
                console.log('meshCnt', meshCnt);
            }
            renderer.render(this.scene, camera);

            const time = performance.now() / 1000;
            const fpsMeasured = Math.min(1 / (time - tPrev), 480);
            probes.push(fpsMeasured);
            tPrev = time;

            // fps = approxRollingAverage(fps, fpsMeasured, 5);
            const reliableFps = getReliableFps(probes);
            if (debug) {
                console.log('fps', reliableFps, 'fpsMeasured', fpsMeasured, `currentStep: ${currentStep} meshCnt: ${meshCnt}`);
            }

            passedThreshold = reliableFps <= GPUBenchmark.thresholds.fps
                || currentStep >= GPUBenchmark.thresholds.maxSteps;
            if (!passedThreshold) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);

        while (!passedThreshold) {
            await sleep(1);
        }

        this.cleanup();
        renderer.dispose();
        const level = GPUBenchmark.stepsToPerfLevel(currentStep);

        if (debug) {
            console.log("device benchmarked at level:", level);
        }

        return level;
    }

    private step(): number {
        const meshPerStep = GPUBenchmark.meshPerFrame * GPUBenchmark.framesPerStep;
        for (let i = 0; i < meshPerStep; i++) {
            const sphere = new Mesh(this.geometry, this.material);
            sphere.frustumCulled = false;

            this.scene.add(sphere);
        }

        return meshPerStep;
    }

    private cleanup() {
        for (const obj of this.scene.children) {
            this.scene.remove(obj);
        }

        // @ts-expect-error null is not assignable to type
        this.scene = null;

        this.material.dispose();
        this.geometry.dispose();

        // @ts-expect-error null is not assignable to type
        this.material = null;
        // @ts-expect-error null is not assignable to type
        this.geometry = null;
    }

    private static stepsToPerfLevel(numSteps: number): GPUPerformanceLevel {
        if (numSteps >= GPUBenchmark.thresholds.maxSteps) {
            return GPUPerformanceLevel.HIGH;
        } else {
            return GPUPerformanceLevel.LOW;
        }
    }
}


function getReliableFps(probes: number[]) {
    const MIN_PROBES = 3;
    const BAD_PROBES_TO_CUT = 0.33; // 33%

    if (probes.length < MIN_PROBES) {
        return 9999;
    }
    const badProbesToCut = Math.max(Math.floor(probes.length * BAD_PROBES_TO_CUT), 1);
    const goodProbes = probes.toSorted((a, b) => a - b).slice(badProbesToCut);

    return goodProbes.reduce((a, b) => a + b) / goodProbes.length - 2;

}
