import {MathUtils} from 'three';

/**
 * @param {object} position
 * @param {number} position.x
 * @param {number} position.y
 * @param {number} position.z
 * @param {number} deg
 * @return {[x: number, y: number, z: number]}
 */
export function getRotation({x, y, z}, deg) {
    const rad = MathUtils.degToRad(deg);
    const x2 = Math.cos(rad) * x - Math.sin(rad) * z;
    const y2 = y;
    const z2 = Math.sin(rad) * x + Math.cos(rad) * z;
    return [x2, y2, z2];
}

export function rotateCamera(camera, deg) {
    camera.position.set(...getRotation(camera.position, deg));
}