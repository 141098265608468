<script setup lang="ts">
import {ref, computed, watch} from 'vue';
import {animate} from 'popmotion';

const props = defineProps<{
    value: '',
}>();


const displayed = ref<string>(props.value);
const isAnimating = ref(false);
let stopAnimation: ReturnType<typeof animate>['stop'];

watch(() => props.value, () => {
    if (typeof stopAnimation === 'function') {
        stopAnimation();
    }
    displayed.value = '';
    isAnimating.value = false;

    if (!props.value.length) {
        return;
    }
    isAnimating.value = true;
    const {stop} = animate({
        from: 0,
        to: props.value.length,
        onUpdate(current) {
            displayed.value = props.value.slice(0, current);
        },
        onComplete() {
            isAnimating.value = false;
        },
        duration: 400,
    });

    stopAnimation = stop;
}, {
    immediate: true,
});
</script>

<template>
    <div class="typewriter-wrap">
        <span :class="isAnimating ? 'typewriter' : ''">
            {{ displayed }}
        </span>
    </div>
</template>

<style scoped>
.typewriter-wrap {min-height: 1.2em; display: block; line-height: 1;}
.typewriter {
    display: inline-block; padding-right: 2px;
    border-right: 2px solid;
    animation: blink .5s step-end infinite alternate;
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}
</style>
